import React from "react"
import PropTypes from "prop-types"
import { createRestaurantOrder } from '../actions/restaurant';
import { cartCount, getRestaurantId, setRestaurantId, getState, saveState, clearCart } from '../actions/storage';
import { colors } from '../lib/utilities'
import ItemMenue from './ItemMenue'
import CartDetails from './CartDetails'

let styles = {
  mainContainer: {
    height: '100vh',
    overflow: 'scroll'
  },
  menuItemPriceItem: {
    fontSize: 10,
    textTransform: 'uppercase',
  },
  modalHeader: {
    background: 'linear-gradient(360deg, rgba(0,0,0,0), rgba(0,0,0,1))',
    minHeight: 85,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    border: 'none'
  },

  menuItemPriceItemContainer: {
    float: 'left',
    marginRight: 15
  },

  categoryFilterContainer: {
    backgroundColor: '#111',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    margin: '5px 0',
    zIndex: 1000,
    padding: 10,
  },

  categoryFilter: {
    fontSize: 15,
    color: '#fff',
    opacity: 1,
    height: 40,
    display: 'inline-block',
    padding: '10px 10px 10px 0',
    margin: 15,
  },

  orderButton: {
    backgroundColor: '#ffba00',
    height: 20,
    width: 100,
    height: 20,
    fontSize: 12,
    padding: 0,
    top: 80,
    position: 'relative',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }

}

class Restaurant extends React.Component {
  state = {
    restaurantDetails: null,
    loading: true,
    orderType: 'TakeAway',
    cartItemsCount: 0
  }

  scrollToDiv (id) {
    var element = document.getElementById(id);
    element.scrollIntoView({behavior: 'smooth'});
  };

  componentDidMount() {
    this.loadData()
  }

  formatPrice(price) {
    return new Intl.NumberFormat("es-AR", {style: 'currency', currency: 'ARS'}).format(price)
  }

  submitOrder() {
    createRestaurantOrder({
      restaurantId: this.props.restaurantId,
      // userId: 0,
      orderType: this.state.orderType,
      tableNumber: 0,
      purchaseOrderItems: this.purchaseOrderItems(),
      dinners: 0
    })
  }

  purchaseOrderItems() {
    return [{}]
  }

  async loadData() {
    // if (getRestaurantId() != this.props.restaurantId) {
      setRestaurantId(this.props.restaurantId)
      clearCart()

      await this.setState({ loading: true })
      let newState = { cartItemsCount: 0 }
      try {
        let restaurantDetails = this.props.restaurantData
        newState.restaurantDetails = restaurantDetails
      } finally {
        newState.loading = false
        this.setState(newState)
        saveState(newState)
      }
    // } else {
    //   let newState = getState() || {}
    //   newState.cartItemsCount = cartCount()
    //   newState.loading = false
    //   await this.setState(newState);
    //   saveState(newState)
    // }
  }

  onItemAdded () {
    const newState = this.state
    newState.cartItemsCount = cartCount()
    this.setState(newState)
  }

  onItemChanged () {
    const newState = this.state
    newState.cartItemsCount = cartCount()
    this.setState(newState)
  }

  // handleScroll () {
  //   var $el = $('.categories-content');
  //   var $menu = $('.restaurant-menu-categories');
  //
  //   if ($el.position().top > 150){
  //     $menu.css({'position': 'unset', 'top': 'unset'});
  //   } else if ($el.position().top > 71) {
  //     $menu.css({'position': 'fixed', 'top': '0px'});
  //   }
  // }

  render () {
    if (this.state.loading) {
      return <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>Cargando...</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    }

    let { restaurantDetails, cartItemsCount } = this.state

    if (!restaurantDetails) {
      return <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>Error al cargar el restaurante...</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    }

    let categories = restaurantDetails.categories.map(category => {
      let menues = category.menues.sort((a, b) => a.order_number - b.order_number).map(menu => {
        let price = null

        if (menu.use_portion_price) {
          price = menu.available_portions.map(p => {
            return <div style={styles.menuItemPriceItemContainer}>
              <small style={styles.menuItemPriceItem}>{ p.name }</small>
              <br/>
              <span>{ this.formatPrice(p.price) }</span>
            </div>
          })
        } else {
          price = menu.price > 0 ? <div>{this.formatPrice(menu.price)} </div> : null
        }

        return <div className="menu-item" key={ `menu-${menu.id}` } >
          <div className="menu-item-details-container">
            <div key={`menu-${menu.id}`} >
              <div className="menu-item-name">
                { menu.name } { menu.tacc_free ? <img src={this.props.imgSinTaccSrc} style={ { height: '40px', paddingBottom: '5px' } }/> : null }
              </div>
              <div className="menu-item-description">
                { menu.description }
              </div>
              <div style={styles.flexRow}>
                <div className="menu-item-price"> {price} </div>
              </div>
            </div>
          </div>

          <div className="menu-item-image-container" data-toggle="modal" data-target={`#item-menu-${menu.id}`}>
            <div className="menu-item-image" style={ { backgroundImage: `url(${menu.image_thumb})` } } key={ `menu-img-${menu.id}` }>
              <div className="btn btn-default" style={styles.orderButton} >
                Ver
              </div>
            </div>
          </div>

          <ItemMenue menueId={menu.id}
                     imgSinTaccSrc={this.props.imgSinTaccSrc}
                     categoryName={category.name}
                     restaurantImage={restaurantDetails.image_original}
                     onItemAdded={(e) => this.onItemAdded()}
                     >
          </ItemMenue>
        </div>
      })

      return <div className="col-xs-12 col-sm-12" id={`category-${category.id}`} key={ `menu-${category.id}` }>
        <div className="menu-section">
          <h2 className="menu-section-title">{category.name}</h2>
          <hr/>
          {menues}
        </div>
      </div>
    })

    let categoriesFilter = restaurantDetails.categories.map(category => {
      return <div key={`category-${category.id}`} style={styles.categoryFilter}
                  onClick={(e) => this.scrollToDiv(`category-${category.id}`)}>
        { category.name }
      </div>})

    return (
      <div onScroll={(e) => this.handleScroll()} style={styles.mainContainer}>
        <header id="header">
          <div className="intro-small" style={ { backgroundImage: `url(${restaurantDetails.image_original})` } }>
            <CartDetails cartItemsCount={cartItemsCount} onItemChanged={(e) => this.onItemChanged()}/>
            <div className="overlay-small">
              <div className="container">
                <div className="intro-text-small">
                  <h1>{restaurantDetails.name }</h1>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="restaurant-menu">
          <div style={styles.categoryFilterContainer} className="restaurant-menu-categories">
            {categoriesFilter}
          </div>
          <div className="row" className="categories-content">
            {categories}
          </div>
        </div>
      </div>
    )
  }
}

Restaurant.propTypes = {
  restaurantId: PropTypes.string,
  store: PropTypes.string,
  imgSrc: PropTypes.string,
  imgIosSrc: PropTypes.string,
  imgAndroidSrc: PropTypes.string,
  dotIconSrc: PropTypes.string,
  ddsIconSrc: PropTypes.string,
  restaurantData: PropTypes.object
};

export default Restaurant
