const backendUrl = 'https://panel.app-comer.com/api/v1/mobile/'
const REQUEST_TOKEN = 'vw0tksesmxr06mwj9459dh568kh0qxs8o3rp0wk97ryy8nw2layejdys'

export const request = async (endpoint, method, postData, uploadFile) => {
  try {
    const url = backendUrl + endpoint
    const fetchMethod = (method || 'get').toUpperCase()
    const payload = {
      method: fetchMethod,
      headers: {
        'Accept': 'application/json',
        'Content-Type': uploadFile ? 'multipart/form-data' : 'application/json',
        'X-REQUEST-TOKEN': REQUEST_TOKEN,
        'Access-Control-Allow-Origin': '*'
      }
    }

    if (fetchMethod === 'POST' || fetchMethod === 'PUT' || fetchMethod === 'DELETE') {
      payload.body = uploadFile ? postData : JSON.stringify(postData)
    }

    const response = await fetch(url, payload)
    if (response.ok) {
      return response.json()
    } else {
      // TODO see if we want to throw this
      throw await response.json()
    }
  } catch(error) {
    // When error -> message
    if (error.message) {
      const message = error.message === "Network request failed"
        ? 'Sin conexión a internet.'
        : error.message
      throw message

    // When error -> error
    } else if (error.error) {
      throw error.error.toString()

    // Default
    } else {
      throw error
    }
  }
}

export default request
