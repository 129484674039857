import React from "react"
import PropTypes from "prop-types"
import { fetchRestaurant } from '../actions/restaurant';
import { colors } from '../lib/utilities'

let styles = {
  detailsLabels: {
    flexDirection: 'row',
  },

  deliveryPriceContainer: {
    backgroundColor: colors.pureWhite,
    padding: 15,
    paddingTop: 2,
    paddingBottom: 2,
    flex: 0,
    flexWrap: 'nowrap',
    marginBottom: 5,
    marginLeft: 10,
    borderRadius: 10,
    textAlign: 'center',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },

  deliveryPrice: {
    color: colors.black,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
    textAlign: 'center'
  },

  closedLabel: {
    backgroundColor: colors.pureWhite,
    padding: 8,
    paddingTop: 1,
    paddingBottom: 1,
    flex: 0,
    flexWrap: 'nowrap',
    marginBottom: 5,
    marginLeft: 10,
    borderRadius: 10,
    textAlign: 'center',
  },

  closedText: {
    color: colors.black,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 13,
    textAlign: 'center'
  },

  menuItemPriceItem: {
    fontSize: 10,
    textTransform: 'uppercase',

  },

  menuItemPriceItemContainer: {
    paddingLeft: 10,
    textAlign: 'right'
  }
}

class Restaurant extends React.Component {

  loadMap() {
    let { restaurantDetails } = this.state
    if (!restaurantDetails) {
      return
    }

    var uluru = {lat: parseFloat(restaurantDetails.lat) || 0, lng: parseFloat(restaurantDetails.lng) || 0};
    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 13,
      center: uluru,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.RoadMap
    });
    var marker = new google.maps.Marker({
      position: uluru,
      map: map,
      label: { color: 'black', fontWeight: 'bold', fontSize: '16px', text: restaurantDetails.name }
    });

    if (restaurantDetails.delivery_map_type == 'radius' && restaurantDetails.delivery_radius > 0) {
      var cityCircle = new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map: map,
          center: uluru,
          radius: restaurantDetails.delivery_radius * 1000
        });
    }

    if (restaurantDetails.delivery_map_type == 'poligon') {
      var points = []
      if (restaurantDetails.delivery_coords) {
        Object.values(restaurantDetails.delivery_coords).forEach(p => {
          var aPoint = new google.maps.LatLng(p.split(',')[0], p.split(',')[1]);
          points.push(aPoint);
        })

        var deliveryArea = new google.maps.Polygon({
          paths: points,
          strokeColor: '#FF0000',
          strokeOpacity: 0.9,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35
        });

        deliveryArea.setMap(map);
      }
    }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            var userPosition = {lat: position.coords.latitude, lng: position.coords.longitude};
            var marker = new google.maps.Marker({
              position: userPosition,
              map: map
            });
          }
        )
      }
  }

  state = {
    restaurantDetails: null,
    loading: true
  }

  componentDidMount() {
    this.loadData()
  }

  formatPrice(price) {
    return new Intl.NumberFormat("es-AR", {style: 'currency', currency: 'ARS'}).format(price)
  }

  async loadData() {
    await this.setState({ loading: true })
    let newState = {}

    try {
      let restaurantDetails = await fetchRestaurant({ id: this.props.restaurantId })
      newState.restaurantDetails = restaurantDetails
    } finally {
      newState.loading = false
      this.setState(newState, this.loadMap)
    }
  }

  render () {
    if (this.state.loading) {
      return <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>Cargando...</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    }

    let { restaurantDetails } = this.state

    if (!restaurantDetails) {
      return <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>Error al cargar el restaurante...</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    }

    let subtitle = Object.keys(restaurantDetails.restaurant_categories).map((item, i) => (restaurantDetails.restaurant_categories[i])).join(' / ')
    let isOpen = true
    let categories = restaurantDetails.categories.map(category => {
    let titlePrice = null

      let menues = category.menues.map(menu => {
        let price = 0

        if (menu.use_portion_price) {
          price = menu.available_portions.map(p => {
            return <div style={styles.menuItemPriceItemContainer}>
              <small style={styles.menuItemPriceItem}>{ p.name }</small>
              <br/>
              <span>{ this.formatPrice(p.price) }</span>
            </div>
          })

          titlePrice = <div key={`menu-${menu.id}`} className="menu-item-name d-flex justify-content-between">
            <div style={{paddingTop: 16}}>{ menu.name }</div>
            <div className="menu-item-price d-flex"> {price} </div>
          </div>
        } else {

          titlePrice = <div className="menu-item-name d-flex justify-content-between">
            <div>{ menu.name }</div>
            <div className="menu-item-price d-flex"> {this.formatPrice(menu.price)} </div>
          </div>
        }

        return <div className="menu-item" key={ `menu-${menu.id}` }>
          {titlePrice}

          <div className="menu-item-description">
            { menu.description }
          </div>
        </div>
      })

      return <div className="col-xs-12 col-sm-6" key={ `menu-${category.id}` }>
        <div className="menu-section">
          <h2 className="menu-section-title">{category.name}</h2>
          <hr/>
          {menues}
        </div>
      </div>
    })

    let attentionTimes = restaurantDetails.formatted_attention_times.map(time => {
      let times = time.times.map(hour => {
        return <span key={`hour-${time.weekday}-${hour.start_time}`}>{hour.start_time} - { hour.end_time }</span>
      })

      return <p key={ `hour-${time.weekday}` }>{time.weekday}: {times}</p>
    })

    let services = restaurantDetails.service_names.map(service => {
      return <p key={ `service-${service}` }>{service}</p>
    })

    return (
      <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>{restaurantDetails.name }</h1>
                  <p>{ subtitle }</p>

                  <div className="download text-center">
                     <h5 className="text-uppercase">
                        Descargate la APP
                     </h5>

                     <div className="d-flex justify-content-center">
                        <div className="col-xs-6 text-center mt-1 mb-2 mr-2">
                           <a href="https://apps.apple.com/us/app/id1523171370" target="_blank">
                            <img src={ this.props.imgIosSrc } height="60px" />
                           </a>
                        </div>
                        <div className="col-xs-6 text-center mt-1 mb-2">
                           <a href="https://play.google.com/store/apps/details?id=com.appcomer" target="_blank">
                            <img  src={ this.props.imgAndroidSrc } height="60px" />
                           </a>
                        </div>
                     </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  { restaurantDetails.dine_in && <div className="header-icons">
                    Salón
                  </div> }

                  { restaurantDetails.delivery && <div className="header-icons">
                    Delivery
                    <br/>
                    { restaurantDetails.delivery_time > 0 && <span>({ restaurantDetails.delivery_time } Min)</span> }
                  </div> }

                  { restaurantDetails.take_out && <div className="header-icons">
                    Take Away
                    <br/>
                    { restaurantDetails.take_out_time > 0 && <span>({ restaurantDetails.take_out_time } Min)</span> }
                  </div> }

                  { restaurantDetails.reservation && <div className="header-icons">
                    Reservas
                  </div> }
                </div>

                <div className="d-flex justify-content-center">
                  <div style={styles.detailsLabels}>
                    {restaurantDetails.delivery &&
                      <div style={styles.deliveryPriceContainer}>
                        { restaurantDetails.use_delivery_description &&
                          <span style={styles.deliveryPrice}>
                            Envío: { restaurantDetails.delivery_description }
                          </span>
                        }
                        { !restaurantDetails.use_delivery_description && <span style={styles.deliveryPrice}>
                            <span>Envío { restaurantDetails.delivery_cost == 0 ? 'gratis' : `${ this.formatPrice(restaurantDetails.delivery_cost) }` } </span>
                            { restaurantDetails.delivery_min_amount > 0 && <span> * Mínimo { this.formatPrice(restaurantDetails.delivery_min_amount) } </span> }
                          </span>
                        }
                      </div>
                    }
                    {!isOpen &&
                      <div style={styles.closedLabel}>
                        <span style={styles.closedText}>
                          Cerrado ahora
                        </span>
                      </div>
                    }
                  </div>
              </div>
            </div>
            </div>
          </div>
        </header>

        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6 ">
                <div className="about-img">
                  <img src={restaurantDetails.image_original} width="100%"></img>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Sobre {restaurantDetails.name }</h2>
                  <hr/>
                  <p>{ restaurantDetails.details }</p>
                </div>
              </div>
            </div>
          </div>
        // </div>

        <div id="restaurant-menu">
          <div className="section-title text-center center">
            <div className="overlay">
              <h2>Menu</h2>
              <hr/>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {categories}
            </div>
          </div>
        </div>

        <div id="location">
          <div className="section-title-map text-center center">
            <div className="overlay">
              <h2>Ubicación y area de delivery</h2>
              <hr/>
            </div>
          </div>
        </div>
        <div id="map" ></div>


        <div id="footer">
          <div className="container text-center justify-content-center">
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <h3>Dirección</h3>
                <div className="contact-item">
                  <p>{restaurantDetails.formatted_address}</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <h3>Horarios de atención</h3>
                <div className="contact-item">
                  { attentionTimes }
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <h3>Servicios</h3>
                <div className="contact-item">
                  { services }
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-center copyrights">
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/somosappcomer">
                      <i className="fab fa-facebook fa-2x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/app_comer/">
                      <i className="fab fa-instagram fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <p>Todos los derechos reservados | APPCOMER</p>
              <div className="social">
                <ul>
                  <li>
                    <a href="/privacy" target="_blank">
                      Politicas de privacidad
                    </a>
                  </li>
                  <li>
                    <a href="/terms" target="_blank">
                      Terminos y condiciones
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Restaurant.propTypes = {
  restaurantId: PropTypes.string,
  imgSrc: PropTypes.string,
  imgIosSrc: PropTypes.string,
  imgAndroidSrc: PropTypes.string,
  dotIconSrc: PropTypes.string,
  ddsIconSrc: PropTypes.string,
};

export default Restaurant
