module.exports = {
  colors: {
    sunflower_yellow: "#fdbd39",
    yellow: "#ffd600",
    tangerine: "#ff9500",
    black: "#000000",
    brownish_grey: "#666666",
    blue_grey: "#8a8a8f",
    light_blue_grey: "#c8c7cc",
    pale_grey: "#efeff4",
    white: "#f9f9f9",
    notification: '#5caceb',
    red: '#fd251e',
    green: '#4ac144',
    pureWhite: '#ffffff',
    darkGrey: '#444444',
    transparent: 'transparent',
    transparentMedium: 'rgba(0,0,0,0.5)',
    transparentStrong: 'rgba(0,0,0,0.8)',
    transparentWhite: 'rgba(255,255,255,0.7)',
    bsGreen: '#5cb85c',
    bsBlue: '#5bc0de',
    bsYellow: '#fdbd39'
  }
}
