import { request } from './backendTransport'
import {reactLocalStorage} from 'reactjs-localstorage';

export const fetchAllPurchaseOrders = () => (
  request(`restaurants/purchase_orders`, 'GET')
)

export const fetchAllRestaurants = () => (
  request(`restaurants`, 'GET')
)

export const fetchRestaurant = ({ id, store }) => (
  request(`restaurants/${id}?store=${store}`, 'GET')
)

export const fetchDashboard = ({ search }) => (
  request(`restaurants/dashboard?${search}`, 'GET')
)

export const searchRestaurant = ({ search, page }) => (
  request(`restaurants?search=${search}&page=${page || 1}`, 'GET')
)

export const updateRestaurantOrder = ({ restaurantId, orderId, userId, purchaseOrderItems }) => (
  request(`restaurants/${restaurantId}/purchase_orders/${orderId}`, 'PUT', {
    user_id: userId,
    purchase_order_items: purchaseOrderItems,
  })
)

export const createRestaurantOrder = ({ restaurantId, userId, orderType, tableNumber, purchaseOrderItems, dinners }) => (
  request(`restaurants/${restaurantId}/purchase_orders`, 'POST', {
    user_id: userId,
    purchase_order_type: orderType,
    table_description: tableNumber,
    purchase_order_items: purchaseOrderItems,
    dinners,
  })
)

export const finishRestaurantOrder = ({ restaurantId, orderId, paymentType }) => (
  request(`restaurants/${restaurantId}/purchase_orders/${orderId}/finish`, 'PUT', {
    payment_type: paymentType
  })
)

export const callWaitress = ({ orderId, tableNumber, restaurantId }) => (
  request(`waiter_calls`, 'POST', {
    purchase_order_id: orderId,
    table_description: tableNumber,
    restaurant_id: restaurantId,
  })
)

export const cancelOrderRequest = (restaurantId, orderId) => (
  request(`restaurants/${restaurantId}/purchase_orders/${orderId}/cancel`, 'PUT')
)

export const getOrderStatus = (restaurantId, orderId) => (
  request(`restaurants/${restaurantId}/purchase_orders/${orderId}/order_status`, 'GET')
)
