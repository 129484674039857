
export const sendMail = async ({ name, restaurantName, phone, description, email, csrf }) => {
  let postData = {
    name: name,
    restaurant_name: restaurantName,
    phone: phone,
    description: description,
    email: email
  }

  const payload = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrf
    }
  }

  payload.body = JSON.stringify(postData)

  let result = await fetch('/contacts', payload)
  return result
}
