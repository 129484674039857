import React from "react"
import PropTypes from "prop-types"
class NavBar extends React.Component {
  render () {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand page-scroll" href="/">
            <img src={this.props.img_src} style={ { height: '50px', paddingTop: '10px' } }/>
          </a>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse flex-row-reverse" id="navbarNavAltMarkup">
            <div className="navbar-nav me-auto mb-2 mb-lg-0">
              <a className="nav-item nav-link" href="/" target="_self">Inicio</a>
              <a className="nav-item nav-link" href="#why" target="_self">Porque?</a>
              <a className="nav-item nav-link active" href="#benefits">Beneficios</a>
              <a className="nav-item nav-link" href="#contact" target="_self">Contacto</a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

NavBar.propTypes = {
  img_src: PropTypes.string
};

export default NavBar



//
// <div className="row align-items-center">
//    <div className="col-6 col-xl-2">
//       <h1 className="mb-0 site-logo">
//          <a href="/">
//          <img src="<%= image_path('logo-completo-transparente.png') %>" style=" height: 60px; padding-top: 10px" />
//          </a>
//       </h1>
//    </div>
//    <div className="col-12 col-md-10 d-none d-xl-block">
//       <nav className="site-navigation position-relative text-right" role="navigation">
//          <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
//             <li><a href="#home-section" className="nav-link">Inicio</a></li>
//             <li><a href="#funcionalidades" className="nav-link">Funcionalidades</a></li>
//             <li><a href="#beneficios" className="nav-link">Beneficios</a></li>
//             <li><a href="#porque" className="nav-link">&#191Por qué tener AppComer?</a></li>
//             <li>
//                <a href="#contact-section" className="nav-link btn btn-primary btn-md text-white" style="color: #000 !important">
//                &#191;Administras un restaurante?
//                </a>
//             </li>
//          </ul>
//       </nav>
//    </div>
//    <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3" style="position: relative; top: 3px;"><a href="#" className="site-menu-toggle js-menu-toggle float-right"><span className="icon-menu h3"></span></a></div>
// </div>
