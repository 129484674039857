import React from "react"
import PropTypes from "prop-types"
import {reactLocalStorage} from 'reactjs-localstorage'
import { colors } from '../lib/utilities'
import { addToCart, cartCount, getOrderItems, getCartTotal, getRestaurantId, setRestaurantId, getState, saveState, clearCart, removeFromCart } from '../actions/storage';

let styles = {
  modalHeader: {
    minHeight: 85,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    border: 'none'
  },

  modalDialog: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    margin: 0,
    backgroundColor: '#000000'
  },

  modalBody: {
    background: '#000',
    color: '#fff',
    border: 'none',
    padding: 0
  },

  headerButton: {
    fontSize: 30,
    color: '#fff',
    opacity: 1,
    height: 50,
  },

  btnPrimary: {
    backgroundColor: '#ffba00',
    width: 'calc(100% - 40px)',
    color: '#000',
    fontSize: 22,
    fontWeight: 'bold'
  },

  modalFooter: {
    justifyContent: 'center',
    background: '#000',
    color: '#fff',
    border: 'none',
    display: 'inline',
    textAlign: 'center'
  },

  notesInput: {
    width: 'calc(100% - 40px)',
    height: 80,
    fontSize: 18,
  },

  notesContainer: {
    textAlign: 'center'
  },

  orderContent: {
    margin: '20px',
  },

  pageTitle: {
    color: '#ffba00',
    fontSize: 30,
    textAlign: 'center',
    width: '100%',
    marginBottom: 20
  },

  notesTitle: {
    color: '#ffba00',
    fontSize: 18,
    fontWeight: 'bold',
    padding: '10px 20px'
  },

  controlsSmall: {
    margin: '2px 20px',
    display: 'flex'
  },

  menueItem: {
    margin: '10px 0'
  },

  menueItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: 'solid 1px #666',
    padding: '0 0 10px',
  },

  quantityControlContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 100,
    padding: 10,
    justifyContent: 'center',
    background: '#000',
    color: '#fff',
    border: 'none'
  },

  quantityControlRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },

  quantityButton: {
    borderRadius: '50%',
    width: 30,
    background: '#ccc',
    textAlign: 'center',
    height: 30,
    fontSize: 25,
    lineHeight: 0,
    fontWeight: 'bold',
    margin: '0 5px',
    border: 'solid 0px #ccc',
    padding: 0
  },

  quantityInput: {
    borderRadius: '50%',
    border: 'solid 3px #ffba00',
    padding: 5,
    width: 30,
    textAlign: 'center',
    height: 30,
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 10px',
    color: '#fff',
    background: '#000'
  },

  totals: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 30px',
    justifyContent: 'space-between',
    fontSize: 30
  }
}

class CartDetails extends React.Component {
  state = {
    notes: '',
    cartItemsCount: 0,
  }

  componentDidMount() {
    this.loadData()
  }

  addQuantity(menueItem) {
    addToCart(menueItem)
    this.props.onItemChanged()
    this.loadData()
  }

  setNotes(e) {
    let state = this.state
    state.notes = e.target.value
    this.setState(state)
  }

  removeQuantity(menueItem) {
    removeFromCart(menueItem)
    this.props.onItemChanged()
    this.loadData()
  }

  formatPrice(price) {
    return new Intl.NumberFormat("es-AR", {style: 'currency', currency: 'ARS'}).format(price)
  }

  async loadData() {
    let newState = {}
    newState.cartItemsCount = cartCount()
    let state = JSON.parse(reactLocalStorage.get('state'))
    newState.menue = state.restaurantDetails.categories.map((c) => c.menues).flat().find((m) => m.id === this.props.menueId)
    this.setState(newState)
  }

  hideModal () {
    $("#modal-cart").modal("hide")
  };

  renderOrderItem(menueItem, showControls = true) {
    return <div key={`${menueItem.name}-${menueItem.portion}-${Math.floor(Math.random() * 10000)}`} >
      <div style={styles.menueItem} >
        <div style={styles.menueItemContainer} >
          <div>
            <span numberOfLines={2} style={styles.menueItemNameText} >
              {menueItem.name}
            </span>
            <div style={styles.menueItemDescription} >
              <span style={styles.menueItemPortionPrice}>{this.formatPrice(menueItem.linePrice)} </span>
              { menueItem.quantity > 1 ? <span>({this.formatPrice(menueItem.price)} c/u)</span> : null }

              { menueItem.portion != '' && (<span style={styles.menueItemPortionText} >{menueItem.portion}</span>) }
            </div>
          </div>
          { showControls && <div style={styles.quantityControlContainer}>
              <div>
                <button type="button" style={styles.quantityButton} onClick={(e) => { this.removeQuantity(menueItem) }}>
                  -
                </button>
              </div>
              <div>
                <input name='quantity' readonly value={menueItem.quantity} style={styles.quantityInput}/>
              </div>
              <div>
                <button type="button" style={styles.quantityButton} onClick={(e) => { this.addQuantity(menueItem) }}>
                  +
                </button>
              </div>
          </div>
          }
        </div>

      </div>
    </div>
  }


  render () {
    let { cartItemsCount } = this.state
    const orderItems = getOrderItems();

    const orderItemsdiv = orderItems.map((orderItem) => {
      return this.renderOrderItem(orderItem);
    })

    const total = getCartTotal()

    let orderContent = <div>
      <div style={styles.orderContent}>

        <div style={styles.pageTitle}>Carrito</div>

        {orderItemsdiv}
      </div>

    </div>

    return (
      <div>
        <div className="modal fade" id="modal-cart" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable" style={styles.modalDialog} role="document">
            <div className="modal-content" style={{background: '#000'}}>
              <div className="modal-header" style={styles.modalHeader}>
                <button type="button" className="close" style={styles.headerButton} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={styles.modalBody}>
                {orderContent}
              </div>
              <div className="modal-footer" style={styles.modalFooter}>
                <div style={styles.totals} >
                  <span numberOfLines={2} style={styles.totalText} >
                    Total
                  </span>
                  <span style={styles.totalText}>{this.formatPrice(total)}</span>
                </div>

                <button type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                        style={styles.btnPrimary}
                        onClick={(e) => { }}>Continuar</button>
              </div>
            </div>
          </div>
        </div>

        { this.props.cartItemsCount > 0 && <button type="button" className="btn btn-link float-right" style={styles.headerButton} data-toggle="modal" data-target="#modal-cart">
            <i className="fas fa-cart-plus"></i> ({ this.props.cartItemsCount })
          </button>
        }
      </div>
    )
  }
}

CartDetails.propTypes = {
  cartItemsCount: PropTypes.number,
  onItemChanged: PropTypes.function
  // imgAndroidSrc: PropTypes.string,
  // dotIconSrc: PropTypes.string,
  // ddsIconSrc: PropTypes.string,
};

export default CartDetails
