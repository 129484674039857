import {reactLocalStorage} from 'reactjs-localstorage';

export const getRestaurantId = () => reactLocalStorage.get('restaurantId')

export const setRestaurantId = (restaurantId) => {
  reactLocalStorage.set('restaurantId', restaurantId);
}

export const getState = () => {
  const sState = reactLocalStorage.get('state')
  return sState ? JSON.parse(sState) : {}
}

export const saveState = (newState) => {
  reactLocalStorage.set('state', JSON.stringify(newState));
}

export const getCurrentCart = () => {
  const sCurrentCart = reactLocalStorage.get('currentCart')
  return sCurrentCart ? JSON.parse(sCurrentCart) : null
}

export const addToCart = (menueItem, restaurantId = null, quantity = 1, portion = null, skipMessage = false) => {
  let currentCart = getCurrentCart()

  if (!currentCart || (restaurantId && currentCart.restaurant_id !== restaurantId)) {
    currentCart = {
      restaurant_id: restaurantId,
      purchase_order_items: []
    };
  }

  let existingItem = null;
  currentCart.purchase_order_items.forEach((item) => {
    if (item.lineIdentifier === menueItem.lineIdentifier) {
      item.quantity += 1;
      item.linePrice = parseFloat(Math.round(item.price * item.quantity * 100) / 100).toFixed(2);
      existingItem = item;
    }
  });

  if (!existingItem) {
    currentCart.purchase_order_items.push({
      image: menueItem.image_thumb,
      name: menueItem.name,
      menueId: menueItem.id,
      portionId: portion ? portion.portion_id : null,
      quantity: quantity,
      price: portion ? portion.price : menueItem.price,
      linePrice: (portion ? portion.price : menueItem.price) * quantity,
      portion: portion ? portion.name : '',
      description: '',
      lineIdentifier: `${menueItem.id}-${Math.floor(Math.random() * 10000)}`
    });
  }

  reactLocalStorage.set('currentCart', JSON.stringify(currentCart));
}

export const removeFromCart = async (menueItem, skipMessage) => {
  let currentCart = getCurrentCart()
  let existingItem = null;

  // var index = currentCart.purchase_order_items.findIndex((t) => t.lineIdentifier == menueItem.lineIdentifier)
  // if (index > -1 ) {
  //   existingItem = currentCart.purchase_order_items[index]
  //   existingItem.quantity -= 1;
  //   existingItem.linePrice = parseFloat(Math.round(item.price * item.quantity * 100) / 100).toFixed(2);
  // }

  currentCart.purchase_order_items.forEach((item) => {
    if (!existingItem && item.lineIdentifier === menueItem.lineIdentifier) {
      item.quantity -= 1;
      item.linePrice = parseFloat(Math.round(item.price * item.quantity * 100) / 100).toFixed(2);
      existingItem = item;
    }
  });

  if (existingItem && existingItem.quantity == 0) {
    var index = currentCart.purchase_order_items.findIndex((t) => t == existingItem)
    currentCart.purchase_order_items.splice(index, 1)
  }


  // currentCart.purchase_order_items = purchaseOrderItems

  await reactLocalStorage.set('currentCart', JSON.stringify(currentCart) );
}

export const cartCount = () => {
  let currentCart = getCurrentCart()
  if (currentCart && currentCart.purchase_order_items) {
    let total = 0;
    currentCart.purchase_order_items.forEach((item) => {
      total += parseFloat(item.quantity);
    })
    return total;
  }
  return 0;
}

export const getCartTotal = () => {
  let currentCart = getCurrentCart()

  if (currentCart && currentCart.purchase_order_items) {
    let total = 0;
    currentCart.purchase_order_items.forEach((item) => {
      total += parseFloat(item.price) * parseFloat(item.quantity);
    })

    return parseFloat(Math.round(total * 100) / 100).toFixed(2);
  }
  return 0;
}

export const getOrderItems = () => {
  let currentCart = getCurrentCart()

  if (currentCart && currentCart.purchase_order_items) {
    return currentCart.purchase_order_items;
  }
  return [];
}

export const getRestaurant = async () => {
  let currentCart = getCurrentCart()

  if (currentCart) {
    return { restaurant_name, restaurant_id, restaurant_address } = currentCart;
  }
  return {};
}

export const clearCart = () => {
  reactLocalStorage.set('currentCart', null);
}
