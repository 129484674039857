require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("popper.js")

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
import '../stylesheets/application'
import './bootstrap_custom.js'
import "@fortawesome/fontawesome-free/js/all";
