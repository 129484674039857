import React from "react"
import PropTypes from "prop-types"
import {reactLocalStorage} from 'reactjs-localstorage'
import { colors } from '../lib/utilities'
import { addToCart } from '../actions/storage'

let styles = {
  menueTitle: {
    color: 'white',
    background: 'linear-gradient(360deg, rgba(0,0,0,1), rgba(0,0,0,0))',
    minHeight: 85,
    width: '100%'
  },

  modalHeader: {
    background: 'linear-gradient(360deg, rgba(0,0,0,0), rgba(0,0,0,1))',
    minHeight: 85,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    border: 'none'
  },

  menueTitleText: {
    fontSize: 28,
    fontWeight: 'bold',
    width: '100%',
    padding: '10px 20px',
  },

  menuItemPrice: {
    fontWeight: 600,
    fontSize: 25,
    width: '100%',
  },

  menueItemPriceValue: {
    color: '#ff9700',
  },

  menuePrice: {
    padding: '0px 20px',
  },

  menuePriceTitle: {
    fontSize: 20,
    padding: '10px 20px 0',
  },


  menueDescription: {
    fontSize: 20,
    padding: '10px 20px',
  },

  menueItemImageContainer: {
    backgroundColor: '#000',
    width: '100%',
    height: 250
  },

  menuItemPriceItem: {
    fontSize: 14,
    textTransform: 'uppercase',
  },

  menuItemPriceItemContainer: {
    paddingRight: 30,
    float: 'left'
  },

  modalDialog: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    margin: 0,
    backgroundColor: '#000000'
  },

  modalBody: {
    background: '#000',
    color: '#fff',
    border: 'none',
    padding: 0
  },

  quantityControlContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    justifyContent: 'center',
    background: '#000',
    color: '#fff',
    border: 'none'
  },

  quantityControlRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },

  quantityButton: {
    borderRadius: '50%',
    padding: 5,
    width: 50,
    background: '#ccc',
    textAlign: 'center',
    height: 50,
    fontSize: 25,
    fontWeight: 'bold',
    margin: '0 10px',
    border: 'solid 0px #ccc',
  },

  quantityInput: {
    borderRadius: '50%',
    border: 'solid 3px #ffba00',
    padding: 5,
    width: 50,
    textAlign: 'center',
    height: 50,
    fontSize: 25,
    fontWeight: 'bold',
    margin: '0 10px',
    color: '#fff',
    background: '#000'
  },

  btnPrimary: {
    backgroundColor: '#ffba00',
    width: 'calc(100% - 40px)',
    color: '#000',
    fontSize: 22,
    fontWeight: 'bold'
  },

  categoryLabel: {
    backgroundColor: '#ffba00',
    borderRadius: 10,
    color: '#000',
    float: 'left',
    margin: '0 20px',
    padding: '2px 10px',
  },

  categoryLabelContainer: {
    width: '100%',
    display: 'inline-block'
  },

  headerButton: {
    fontSize: 50,
    color: '#fff',
    opacity: 1,
  },

  modalFooter: {
    justifyContent: 'center',
    background: '#000',
    color: '#fff',
    border: 'none'
  },

  notesInput: {
    width: 'calc(100% - 40px)',
    height: 80,
    fontSize: 18,
  },

  notesContainer: {
    textAlign: 'center'
  },

  notesTitle: {
    color: '#ffba00',
    fontSize: 18,
    fontWeight: 'bold',
    padding: '10px 20px'
  },

  controlsSmall: {
    margin: '2px 20px',
    display: 'flex'
  },

  controlsTastes: {
    margin: '2px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },

  portionCheck: {
    margin: '0 10px',
    accentColor: '#ffba00',
    height: 20,
    width: 20,
    float: 'left'
  },

  portionCheckText: {
    fontSize: 20,
    lineHeight: '22px',
  },

  portionCheckTextSelected: {
    fontSize: 20,
    color: '#ffba00',
    lineHeight: '22px',
  },

  quantityButtonSmall: {
    borderRadius: '50%',
    width: 40,
    background: '#ccc',
    textAlign: 'center',
    height: 40,
    fontSize: 15,
    fontWeight: 'bold',
    margin: '0 5px',
    border: 'solid 0px #ccc',
  },

  quantityInputSmall: {
    borderRadius: '50%',
    border: 'solid 3px #ffba00',
    width: 40,
    textAlign: 'center',
    height: 40,
    fontSize: 15,
    margin: '0 3px',
    color: '#fff',
    background: '#000'
  },

  quantityControlContainerSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    background: '#000',
    color: '#fff',
    border: 'none'
  },
}

class ItemMenue extends React.Component {
  state = {
    menue: null,
    quantity: 1,
    notes: '',
    selectedSides: [],
    selectedAdditionals: [],
    tastes: []
  }

  componentDidMount() {
    this.loadData()
  }

  addQuantity() {
    let state = this.state
    state.quantity++
    this.setState(state)
  }

  setQuantity(e) {
    let state = this.state
    state.quantity = e.target.value;
    this.setState(state);
  }

  setNotes(e) {
    let state = this.state
    state.notes = e.target.value;
    this.setState(state);
  }

  removeQuantity() {
    let state = this.state
    const { tastes } = this.state

    if (state.quantity > 1) {
      state.quantity--;

      tastes.forEach((t) => {
        t.quantity = 0
      })

      state.tastes = tastes
      this.setState(state)
    }
  }

  toggleSide(side) {
    let state = this.state
    if (state.selectedSides.includes(side)) {
      var index = state.selectedSides.findIndex((s) => s == side)
      state.selectedSides = state.selectedSides.splice(1, index)
    } else {
      state.selectedSides.push(side)
    }
    this.setState(state)
  }

  toggleAdditional(additional) {
    let state = this.state
    if (state.selectedAdditionals.includes(additional)) {
      var index = state.selectedAdditionals.findIndex((s) => s == additional)
      state.selectedAdditionals = state.selectedAdditionals.splice(1, index)
    } else {
      state.selectedAdditionals.push(additional)
    }
    this.setState(state)
  }

  formatPrice(price) {
    return new Intl.NumberFormat("es-AR", {style: 'currency', currency: 'ARS'}).format(price)
  }

  async loadData() {
    let newState = {}
    let state = JSON.parse(reactLocalStorage.get('state'))
    newState.menue = state.restaurantDetails.categories.map((c) => c.menues).flat().find((m) => m.id === this.props.menueId)
    this.setState(newState)
  }

  toggleTaste = (taste) => {
    const { tastes } = this.state
    const { menue } = this.state
    const isSelected = tastes.includes(taste)

    if (isSelected) {
      var index = tastes.findIndex((t) => t == taste)
      tastes.splice(index, 1)
      this.setState({ tastes })
    } else {
      if (tastes.length < menue.menue_options_quantity) {
        taste.quantity = 1
        tastes.push(taste)
        this.setState({ tastes })
      }
    }
  }

  selectedTastes() {
    let { tastes } = this.state

    return tastes.reduce((memo, taste) => memo + taste.quantity || 0, 0);
  }

  canAddTastes() {
    const { menue } = this.state
    return this.selectedTastes() < menue.menue_options_quantity * this.state.quantity
  }

  addTaste(taste) {
    const { tastes } = this.state.menue
    if (this.canAddTastes()) {
      let index = tastes.findIndex((t) => t == taste)
      if (index > -1) {
        tastes[index].quantity = (tastes[index].quantity || 0) + 1
      } else {
        taste.quantity = 1
        tastes.push(taste)
      }
      this.setState({ tastes })
    }
  }

  removeTaste = (taste) => {
    const { tastes } = this.state
    let index = tastes.findIndex((t) => t == taste)

    if (index > -1) {
      if (!tastes[index].quantity || tastes[index].quantity == 0) {
        return
      }

      tastes[index].quantity = (tastes[index].quantity || 0) - 1
    }

    this.setState({ tastes })
  }

  quantityForTaste = (taste) => {
    const { tastes } = this.state
    let index = tastes.findIndex((t) => t == taste)

    if (index > -1) {
      return tastes[index].quantity || 0
    }
    return 0
  }

  addToOrder (menue) {
    addToCart(menue, this.props.restaurantId, this.state.quantity)
    this.props.onItemAdded()
    // this.hideModal(menue.id)
  }

  hideModal (menueId) {
    $(`#item-menu-${menueId}`).modal("hide");
  };

  render () {
    if (!this.state || !this.state.menue) {
      return <div></div>
    }

    const menue = this.state.menue
    const selectedSides = this.state.selectedSides
    const selectedAdditionals = this.state.selectedAdditionals

    const menueId = this.props.menueId
    let price, titlePrice

    if (menue.use_portion_price) {
      price = menue.available_portions.map(p => {
        return <div style={styles.menuItemPriceItemContainer} key={`portion-${p.name}`}>
          <small style={styles.menuItemPriceItem}>{ p.name }</small>
          <br/>
          <span style={styles.menueItemPriceValue}>{ this.formatPrice(p.price) }</span>
        </div>
      })

      titlePrice = <div style={styles.menuItemPrice}>
        {price}
      </div>
    } else {
      titlePrice = <div>
        { menue.price > 0 ? <div style={styles.menuItemPrice}>
          <span style={styles.menueItemPriceValue}>{this.formatPrice(menue.price)}</span>
        </div> : null }
      </div>
    }

    const sides = menue.sides
    const additionals = menue.additionals

    return (
      <div className="modal fade" id={`item-menu-${menueId}`} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" style={styles.modalDialog} role="document">
          <div className="modal-content" style={{background: '#000'}}>
            <div className="modal-header" style={styles.modalHeader}>
              <button type="button" className="close" style={styles.headerButton} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={styles.modalBody}>
              <div style={styles.menueItemImageContainer}>
                <div style={{ backgroundImage: `url(${menue.image_original || this.props.restaurantImage})`,
                              width: '100%',
                              height: 245,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              display: 'flex',
                              alignItems: 'flex-end' }}
                      key={ `menu-img-${menueId}` }>
                  <div style={styles.menueTitle}>
                    <div style={styles.categoryLabelContainer}>
                      <label style={styles.categoryLabel}>
                        {this.props.categoryName}
                      </label>
                    </div>
                    <div style={styles.menueTitleText}>
                      {menue.name}
                      <div style={{ float: 'left'}}>
                        { menue.tacc_free ? <img src={this.props.imgSinTaccSrc} style={ { height: '40px', paddingBottom: '5px' } }/> : null }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.menuePrice}>
                {titlePrice}
              </div>
              <div style={styles.menueDescription}>
                {menue.description}
              </div>


              <div>
                { menue.has_sides && menue.sides.length >= 1 && <div style={styles.notesTitle}>
                    Guarniciones - Seleccione máximo {menue.max_sides_quantity} item(s)
                  </div>
                }
                { menue.has_sides && menue.sides.length >= 1 &&
                    menue.sides.map(side => {
                      return <div  key={`portion-${menue.id}-${side.id}`}>
                        <div style={styles.controlsSmall}>
                          <input id={`portion-${menue.id}-${side.id}`}
                                 style={styles.portionCheck}
                                 disabled={selectedSides.length >= menue.max_sides_quantity && !selectedSides.includes(side)}
                                 type="checkbox"
                                 onChange={() => { this.toggleSide(side) }} />
                          <label className={ selectedSides.length >= menue.max_sides_quantity && !selectedSides.includes(side) ? 'disabled' : ''}
                                 style={selectedSides.includes(side) ? styles.portionCheckTextSelected : styles.portionCheckText}
                                 for={`portion-${menue.id}-${side.id}`}>
                            {side.name}
                            { side.price > 0 ?  <span>({this.formatPrice(side.price)})</span> : null }
                            </label>
                        </div>
                      </div>
                    })
              }
              </div>

              <div>
                { menue.has_tastes && menue.tastes.length >= 1 && <div style={styles.notesTitle}>
                    Gustos / Sabores
                  </div>
                }
                { menue.has_tastes && menue.tastes.length >= 1 &&
                    menue.tastes.map(taste => {
                      return <div style={styles.controlsTastes} key={`portion-${menue.id}-taste-${taste.id}`}>
                        <div style={styles.controlsTastes}>
                          {taste.title}
                        </div>
                        <div style={styles.controlsSmall}>
                          <div style={styles.quantityControlContainerSmall}>
                              <div>
                                <button type="button" style={styles.quantityButtonSmall} onClick={(e) => { this.removeTaste(taste) }}>
                                  -
                                </button>
                              </div>
                              <div>
                                <input name='quantity'readonly value={this.quantityForTaste(taste)} onChange={(e) => { this.setQuantityTaste(taste) }} style={styles.quantityInputSmall}/>
                              </div>
                              <div>
                                <button type="button" style={styles.quantityButtonSmall} onClick={(e) => { this.addTaste(taste) }}>
                                  +
                                </button>
                              </div>
                          </div>
                        </div>
                      </div>
                    })
              }
              </div>

              <div>
                { menue.has_additionals && menue.additionals.length >= 1 && <div style={styles.notesTitle}>
                    Adicionales - Seleccione máximo {menue.max_additionals_quantity} item(s)
                  </div>
                }
                { menue.has_additionals && menue.additionals.length >= 1 &&
                    menue.additionals.map(additional => {
                      return <div  key={`portion-${menue.id}-add-${additional.id}`}>
                        <div style={styles.controlsSmall}>
                          <input id={`portion-${menue.id}-add-${additional.id}`}
                                 style={styles.portionCheck}
                                 disabled={selectedAdditionals.length >= menue.max_additionals_quantity && !selectedAdditionals.includes(additional)}
                                 type="checkbox"
                                 onChange={() => { this.toggleAdditional(additional) }} />
                          <label style={selectedAdditionals.includes(additional) ? styles.portionCheckTextSelected : styles.portionCheckText}
                          for={`portion-${menue.id}-add-${additional.id}`}>
                            {additional.name}
                            { additional.price > 0 ?  <span>({this.formatPrice(additional.price)})</span> : null }
                            </label>
                        </div>
                      </div>
                    })
              }
              </div>

              <div style={styles.notesTitle}>Notas para este producto</div>
              <div style={styles.notesContainer}>
                <textarea name='notes' value={this.state.notes} maxLength="100" style={styles.notesInput} onChange={(e) => { this.setNotes(e) }}/>
              </div>
            </div>

            <div style={styles.quantityControlContainer}>
                <div>
                  <button type="button" style={styles.quantityButton} onClick={(e) => { this.removeQuantity() }}>
                    -
                  </button>
                </div>
                <div>
                  <input name='quantity' readonly value={this.state.quantity} onChange={(e) => { this.setQuanty() }} style={styles.quantityInput}/>
                </div>
                <div>
                  <button type="button" style={styles.quantityButton} onClick={(e) => { this.addQuantity() }}>
                    +
                  </button>
                </div>
            </div>
            <div className="modal-footer" style={styles.modalFooter}>
              <button type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      style={styles.btnPrimary}
                      onClick={(e) => { this.addToOrder(menue);}}>Agregar a la orden</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ItemMenue.propTypes = {
  menueId: PropTypes.number,
  imgSinTaccSrc: PropTypes.string,
  restaurantImage: PropTypes.string,
  categoryName: PropTypes.string,
  restaurantId: PropTypes.number,
  onItemAdded: PropTypes.function
  // imgAndroidSrc: PropTypes.string,
  // dotIconSrc: PropTypes.string,
  // ddsIconSrc: PropTypes.string,
};

export default ItemMenue
