import React from "react"
import { fetchAllRestaurants } from '../actions/restaurant';
import { sendMail } from '../actions/contact';

class Contact extends React.Component {
  state = {
    loading: false,
    name: '',
    restaurantName: '',
    phone: '',
    description: '',
    email: '',
    emailError: false,
    emailSuccess: false
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async send() {

    if (this.state.loading) {
      return
    }

    let newState = {}
    newState.errorMessage = ''

    if (this.validateEmail(this.state.email)) {
      await this.setState({ loading: true })

      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

      let response = await sendMail({
        name: this.state.name,
        restaurantName: this.state.restaurantName,
        phone: this.state.phone,
        description: this.state.description,
        email: this.state.email,
        csrf: csrf
      })

      if (response.ok) {
        newState.name = ''
        newState.restaurantName = ''
        newState.phone = ''
        newState.description = ''
        newState.email = ''
        newState.emailError = false
        newState.emailSuccess = true
      } else {
        newState.emailError = true
        newState.emailSuccess = false
      }
      newState.loading = false
    } else {
      newState.loading = false
      newState.emailError = true
      newState.emailSuccess = false
      newState.errorMessage = 'El email ingresado es incorrecto'
    }
    this.setState(newState)

  }

  render () {
    return <div>
      <div className="row form-group">
         <div className="col-md-6 mb-3">
            <label className="text-black" >Nombre</label>
            <input type="text" name="name" id="name" className="form-control" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })}/>
         </div>
         <div className="col-md-6 mb-3 ">
            <label className="text-black" >Teléfono</label>
            <input type="text" name="phone" id="phone" className="form-control"  value={this.state.phone} onChange={(event) => this.setState({ phone: event.target.value })}/>
         </div>
         <div className="col-md-12 mb-3 ">
            <label className="text-black" >Email</label>
            <input type="email" name="email" id="email" className="form-control"  value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })}/>
         </div>
         <div className="col-md-12 mb-3 ">
            <label className="text-black" >Nombre del Restaurante</label>
            <input type="text" name="restaurant_name" id="restaurant_name" className="form-control"  value={this.state.restaurantName} onChange={(event) => this.setState({ restaurantName: event.target.value })}/>
         </div>

         <div className="col-md-12 mb-3 ">
            <label className="text-black" >Mensaje</label>
            <textarea type="text" name="description" id="description" className="form-control"  value={this.state.description} onChange={(event) => this.setState({ description: event.target.value })}/>
         </div>
      </div>
      <div className="row form-group">
         <div className="col-md-12">
            <input type="submit" value={`${this.state.loading ? 'Enviando' : 'Enviar'}`} onClick={() => this.send()} className="btn btn-info btn-md" />
         </div>
      </div>

      { this.state.emailSuccess &&
        <div className="alert alert-success">
          Gracias por contactarnos, nos comumicaremos a la brevedad.
        </div>
      }

      { this.state.emailError &&
        <div className="alert alert-danger">
          { this.state.errorMessage ? this.state.errorMessage : 'No pudimos enviar tu consulta, por favor revisá los datos ingresados o intentá más tarde.' }
        </div>
      }
    </div>
  }
}

export default Contact
