import React from "react"
import { fetchAllRestaurants } from '../actions/restaurant';

class Home extends React.Component {
  state = {
    restaurants: null,
    loading: true
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    await this.setState({ loading: true })
    let newState = {}

    try {
      let restaurants = await fetchAllRestaurants()
      newState.restaurants = restaurants
    } finally {
      newState.loading = false
      this.setState(newState)
    }
  }

  render () {
    if (this.state.loading) {
      return <div>
        <header id="header">
          <div className="intro" style={ { backgroundImage: `url(${this.props.imgSrc})` } }>
            <div className="overlay">
              <div className="container">
                <div className="intro-text">
                  <h1>Cargando...</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

    }

    let { restaurants } = this.state

    let restaurantsDetails = restaurants.map(restaurant => {
      return <div key={`restaurant-${restaurant.id}`} className="col-xs-12 col-md-6 col-lg-4">
        <div className="restaurants-item" >
          <div className="hover-bg" >
            <a href={`/restaurants/${restaurant.id}`} data-lightbox-gallery="gallery1">
              <div className="hover-text">
                <h4>{ restaurant.name }</h4>
              </div>
              <div style={ {
                backgroundImage: `url("${restaurant.image_original}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '250px',
                width: '100%'
              } }>
              </div>
            </a>
          </div>
        </div>
      </div>
    })

    return (
      <div id="restaurants">
        <div className="section-title text-center center">
          <div className="overlay">
            <h2>Restaurantes</h2>
            <hr/>
          </div>
        </div>
        <div className="container">
          <div className="row restaurants-items">
            { restaurantsDetails }
          </div>
        </div>
      </div>
    )
  }
}

export default Home
