import React from "react"
import PropTypes from "prop-types"
class NavBar extends React.Component {
  render () {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand page-scroll" href="/">
            <img src={this.props.img_src} style={ { height: '50px', paddingTop: '10px' } }/>
          </a>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse flex-row-reverse" id="navbarNavAltMarkup">
            <div className="navbar-nav me-auto mb-2 mb-lg-0">
              <a className="nav-item nav-link" href="#restaurants" target="_self">Restaurantes</a>
              <a className="nav-item nav-link" href="/restaurant_contact" target="_self">¿Administras un restaurante?</a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

NavBar.propTypes = {
  img_src: PropTypes.string
};

export default NavBar
